import React from "react"
import useMediaQuery from "../../../hooks/useMediaQuery"

interface V2Props {
  Title: string
  Content: string
  IMGSrc: string
  ButtonText: string
}

const V2 = ({ Title, Content, IMGSrc, ButtonText }: V2Props) => {
  const isDesktop = useMediaQuery("(min-width: 1400px)")

  return (
    <div>
      {isDesktop ? (
        <div className="flex flex-col items-center justify-center h-auto mb-2 w-[100%]">
          <div className="flex flex-row items-center justify-center w-[58%] ml-5">
            <div className="font-bold text-3xl text-IntermetaDarkPurple w-[200%] mr-12">
              {Title}
            </div>
            <div className="text-md mt-7 mr-20 text-IntermetaDarkPurple w-[200%]">
              {Content}
            </div>
            <div className="border-2 border-IntermetaDarkPurple mt-2 aspect-[6/1] w-46 py-3 flex items-center justify-center text-md text-IntermetaDarkPurple">
              {ButtonText}
            </div>
          </div>
          <div className="flex justify-center w-[100%] h-3/4">
            <div className="w-[64%] h-96 mt-3 ml-28 bg-blue-400 aspect-[5/4] rounded-tr-3xl rounded-bl-3xl">
              {IMGSrc}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-auto pb-2">
          <div className="flex flex-col items-center justify-center">
            <div className="w-[100%] font-bold text-3xl mt-10 text-IntermetaDarkPurple ml-6">
              {Title}
            </div>
            <div className="flex justify-center w-[100%] h-3/4">
              <div className="w-[100%] h-92 mt-3 bg-blue-400 aspect-[5/4] ">
                {IMGSrc}
              </div>
            </div>
            <div className="text-md ml-5 my-10 text-IntermetaDarkPurple">
              {Content}
            </div>
            <div className="flex items-center justify-center w-[90%]">
              <div className="border-2 border-IntermetaDarkPurple text-IntermetaDarkPurple mt-2 mb-20 p-2 aspect-[6/1] w-[80%] flex items-center justify-center text-md">
                {ButtonText}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default V2
