import React from "react"
import Footer from "../components/Footer/Footer"
import ContentV2 from "../components/ContentV/ContentV2/ContentV2"
import ContentV6 from "../components/ContentV/ContentV6/ContentV6"
import Navbar from "../components/Navbar/Navbar"
import Header from "../components/Headers/Header"

import Background from "../images/investorsBackground.png"
import BackgroundMobile from "../images/investorsBackgroundM.png"

const EthicsPage = () => {
  return (
    <div>
      <Navbar />
      <Header
        title="ethics"
        background={Background}
        backgroundMobile={BackgroundMobile}
      />
      <div className="bg-white w-[100%] h-auto pb-10 text-IntermetaDarkPurple">
        <ContentV2
          Title="Title 1"
          Content="PAR 1: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod. Lorem ipsum dolor sit amet."
          IMGSrc="Img"
          ButtonText="Lorem Ipsum"
        />
        <ContentV6
          Title="Title 2"
          Content="PAR 1: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod. Lorem ipsum dol"
          IMGSrc="Img"
          ButtonText="Lorem Ipsum"
          Shifted={false}
        />
      </div>
      <Footer />
    </div>
  )
}

export default EthicsPage
